import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

const http = axios.create({ baseURL: `${baseURL}` });

function getAuthHeader() {
  let authHeader = { "Content-Type": "application/json" };
  // get token from cookies
  const token = localStorage.getItem('kt-auth-react-v')
  // {"status":"success","message":"Login Successful","new_device":false,"api_token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NGZhZTliZjUyMGM1NWJlNTFlYTM2M2IiLCJpYXQiOjE3MzA3OTEyMDJ9.jn-uot0XpAeII3qS1vmuFfA8RGjLLAtUgNvBU90vouo","user":"64fae9bf520c55be51ea363b"}
  
  // if api_token is available in token, add it to the header
  const tokenData = JSON.parse(token)
  if (tokenData.api_token) {
    authHeader = { Authorization: `${tokenData.api_token}` };
  }
  return authHeader;
}

function get(url, headers = {}, params = {}) {
  return http.get(url, {
    params,
    headers: { ...getAuthHeader(), ...headers },
  });
}

function post(url, data, headers = {}, params = {}) {
  return http.post(url, data, {
    ...params,
    headers: { ...getAuthHeader(), ...headers },
    additionalData: { ...params },

  });
}

function put(url, data, headers = {}) {
  return http.put(url, data, {
    headers: { ...getAuthHeader(), ...headers },
  });
}

function remove(url, data, headers = {}, params = {}) {
  return http.delete(
    url,
    {
      ...params,
      headers: { ...getAuthHeader(), ...headers },
    },
    data
  );
}

export default { get, post, put, remove };
