import React, { useEffect, useRef } from 'react';
import ReactECharts from 'echarts-for-react';

const EChartComponent = ({ data, chartName, colorCode }) => {
    const chartRef = useRef(null);

    const getOption = () => {
        return {
            title: {
                text: chartName,
                left: 'center',
                textStyle: {
                    fontSize: 16
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow' // Tooltip shadow style for better readability
                },
                formatter: (params) => {
                    const { name, value } = params[0];
                    const [customerName, date] = name.split('(');
                    return `Customer: ${customerName}<br>Count: ${value}`;
                }
            },
            xAxis: {
                type: 'category',
                data: data.map(item => `${item.customer_name}`),
                axisLabel: {
                    rotate: 90,
                    interval: 0,
                    formatter: (value) => value.length > 8 ? value.slice(0, 8) + '..' : value, // Optional: truncate long labels
                }
            },
            yAxis: {
                type: 'value',
                name: 'Count',
                nameLocation: 'middle',
                nameGap: 35
            },
            series: [{
                name: chartName,
                type: 'bar',
                color: colorCode,
                data: data.map(item => item.count),
                barWidth: '50%', // Adjust width of bars
                label: {
                    show: true,
                    position: 'top'
                }
            }]
        };
    };

    // Handle window resize and re-render chart
    useEffect(() => {
        const handleResize = () => {
            if (chartRef.current) {
                chartRef.current.getEchartsInstance().resize();
            }
        };

        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Ensure chart is resized after initial render
    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.getEchartsInstance().resize();
        }
    }, []);

    return (
        <ReactECharts
            ref={chartRef}
            style={{ height: 400, width: '100%' }} // Adjust width for responsiveness
            className='mt-4'
            option={getOption()}
        />
    );
}

export default EChartComponent;
