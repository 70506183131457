import React from 'react'
import {useState, useEffect} from 'react'
import * as Yup from 'yup'
import OtpInput from 'react-otp-input';
import {getUserByToken} from '../core/_requests'

import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import axios from 'axios'
import {useAuth} from '../core/Auth'

const OTPVerify = () => {
    /* eslint-disable jsx-a11y/anchor-is-valid */
    
    const [contentLoading, setContentLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
    

    const OTPSchema = Yup.object().shape({
        otp: Yup.string()
            .min(6, 'Minimum 3 symbols')
            .max(6, 'Maximum 50 symbols')
            .required('OTP is required'),
    })

    const initialValues = {
        otp: '',
    }


    const [loading, setLoading] = useState(false)
    const [loadingOTP, setLoadingOTP] = useState(false)
    const [validToken, setValidToken] = useState(false)
    const [otp, setOtp] = useState('')


    // const {saveAuth, setCurrentUser} = useAuth()

    const navigate = useNavigate()
    const formik = useFormik({
        initialValues,
        validationSchema: OTPSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
        setLoading(true)
        try {
            const user = window.location.pathname.split('/')[3]
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/adminuser/verify-login/${user}/${formik.values.otp}`, values)
            if(res.data.status === true){
                setSubmitting(false)
                setLoading(false)
                setStatus(res.data.message)
                saveAuth(res.data)
                var data = res.data
                const {data: user} = await getUserByToken(data.api_token, data.user)
                setCurrentUser(user)
                localStorage.setItem('device_code', data.device_code)
                // redirect 5 seconds later
                setTimeout(() => {
                    // navigate('/auth/login')
                    navigate('/')
                }, 3000)
            }else{
                setSubmitting(false)
                setLoading(false)
                setStatus(res.data.message)
            }
            
            
        } catch (error) {
            setSubmitting(false)
            setLoading(false)
            console.log(error);
            setStatus('Something went wrong. Please try again later.')
        }
        },
    })



    return (
        <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
        >
        {/* begin::Heading */}
        <div className='text-center mb-10'>
            {/* logo from public folder */}
            <img src={toAbsoluteUrl('/media/logos/logo-icon.webp')} className='mb-3' alt='Logo' width="80" />
        </div>

                <>
            <div className='text-center mb-11'>
                <h1 className='text-dark fw-bolder mb-3'>Verify OTP</h1>
                {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
            </div>
            

            {formik.status ? (
                <div className='mb-lg-15 alert alert-info'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            ) : (
                <></>
            )}

            
            {/* end::Form group */}

            {/* begin::Input group */}
            <div className='fv-row mb-10'>
                {/* <label className='form-label fs-6 fw-bolder text-dark'>OTP</label> */}
                <OtpInput
                    value={formik.values.otp}
                    onChange={(e) => {
                        formik.setFieldValue('otp', e)
                    }
                    }
                    // inputType="number" 
                    containerStyle={{justifyContent: 'center', fontSize: '3rem', padding: '1rem'}}
                    isInputNum={true}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    />
                {formik.touched.otp && formik.errors.otp ? (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.otp}</div>
                </div>
                ) : null}
            </div>

            {/* begin::Action */}
            <div className='d-grid mb-10'>
                <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary'
                disabled={formik.isSubmitting || !formik.isValid}
                >
                {!loading && <span className='indicator-label'>Continue</span>}
                {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                )}
                </button>
                {/* resend otp */}
                <div className='d-grid mb-10 mt-5' onClick={() => {
                    // resend otp
                    setLoadingOTP(true)
                    const user = window.location.pathname.split('/')[3]
                    axios.post(`${process.env.REACT_APP_API_URL}/adminuser/resend-otp/${user}`)
                    .then(res => {
                        if(res.data.status === true){
                            formik.setStatus(res.data.message)
                        }else{
                            formik.setStatus(res.data.message)
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    }).finally(() => {
                        setLoadingOTP(false)
                    })

                }}>
                    <button type="button" className="btn btn-secondary fw-bolder btn-sm">
                        {!loadingOTP && <span className='indicator-label'>Resend OTP</span>}
                        {loadingOTP && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    {/* Resend OTP */}
                    
                  </div>  
                    
            </div>
            {/* end::Action */}
                </>
                
                

        <div className='text-gray-500 text-center fw-semibold fs-6'>
            <Link to='/auth/login' className='link-primary'>
            Sign in
            </Link> | &nbsp;
            <Link to='/' className='link-primary'>
            Home
            </Link>

        </div>
        </form>
    )
}


export default OTPVerify
