import httpService from "./http.service";


export const regenerateResultApi = (data) =>
  httpService
      .post("/recording/re-generate-result", data)
      .then(({ data }) => data)
      .catch((err) => Promise.reject(err.response.data));

export const reAttemptResultApi = (data) =>
  httpService
      .post("/recording/re-attempt-result-admin", data)
      .then(({ data }) => data)
      .catch((err) => Promise.reject(err.response.data));

// export const recordingGetApi = (
//   limit,
//   query
// ) => {
//   let url = "/recording/all";
//   if (limit) {
//     url += `?limit=${limit}&&query=${query}`;
//   }
//   return (
//     httpService
//       // .get("/recording/")
//       .get(url)
//       .then(({ data }) => data)
//       .catch((err) => Promise.reject(err.response.data))
//   );
// }
export const recordingGetApi = (
  limit, 
  offset, 
  fromDate, 
  toDate, 
  sortBy, 
  query,
  customerSelect
) => {
  let url = "/recording/all?";

  if (limit) url += `limit=${limit}&`;
  if (offset) url += `offset=${offset}&`;
  if (fromDate) url += `fromDate=${fromDate}&`;
  if (toDate) url += `toDate=${toDate}&`;
  if (sortBy) url += `sort=${sortBy}&`;
  if (query) url += `query=${encodeURIComponent(query)}&`;
  if(customerSelect) url += `customerSelect=${customerSelect}&`;

  return httpService
      .get(url)
      .then(({ data }) => data)
      .catch((err) => Promise.reject(err?.response?.data || err));
};

    

export const downloadAudioFileApi  = (id) =>
  httpService
   .get(`/recording/download/${id}`)
   .then(({ data }) => data)
   .catch((err) => Promise.reject(err.response.data));
