import {useEffect, useState} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import {KTSVG} from '../../_metronic/helpers'
import { Form } from 'react-bootstrap'
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import {toast} from 'react-toastify';
import Modal from 'react-bootstrap/Modal'
import { Funnel,  Calendar } from 'react-bootstrap-icons';
import moment from 'moment';

import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css fil

// import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Customers = () => {
    const token =  localStorage.getItem('kt-auth-react-v.api_token')
    const [offset, setOffset] = useState(0);
    const [fromDate, setFromDate] = useState(''); //new Date().toISOString().slice(0, 10) '2020-01-01'
    const [toDate, setToDate] = useState('');
    const [sortBy, setSortBy] = useState(-1);
    const [contentLoading, setContentLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [allData, setAllData] = useState([]);
    const [pageCount, setPageCount] = useState(0)
    const [totalItems, setTotalItems] = useState(0)
    const [dataPerPage, setDataPerPage] = useState(10)
    const [lgshow, setShow] = useState(false);

    const [full_name, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [practice_address, setPracticeAddress] = useState('');
    const [practice_name, setPracticeName] = useState('');
    const [lead_source, setLeadSource] = useState('');
    const [practice_website, setPracticeWebsite] = useState('');
    const [state, setState] = useState('');
    const [is_verified, setIsVerified] = useState(true);
    const [status, setStatus] = useState(1);
    const [subscriptionData, setSubscriptionData] = useState([]);

    
    // const [blogDescription, setBlogDescription] = useState('');
    const [customerStatus, setCustomerStatus] = useState(1);

    
    const [modalTitle, setModalTitle] = useState('View Details');
    const [viewMode, setViewMode] = useState(false);
    
    const [customerId, setCustomerId] = useState('');
    const [calenderDates, setCalenderDates] = useState([
        {
            startDate: null,
            endDate: new Date(),
            key: 'selection',
            
        }
    ]);
    // const theme = 'snow';

    
    
    // const  formats = [
    //     'header',
    //     'bold', 'italic', 'underline', 'strike', 'blockquote',
    //     'list', 'bullet', 'indent',
    //     'link', 'image'
    //   ],
    // const { quill, quillRef } = useQuill({ theme, modules });
    
    useEffect(() => {
        getData();
    }, [sortBy, offset, calenderDates, fromDate, toDate, searchQuery])

    const getAllData = (data, offset) => {
        return (
            
            data.map(customer =>{
                return (
                <tr key={customer._id}>
                    <td></td>
                    <td>
                        {customer?.full_name}
                    </td>
                    <td>
                        {customer?.email}
                    </td>
                    <td>
                        {customer?.phone_number}
                    </td>
                    <td>
                        {/* status */}
                        <span className={`badge badge-light-${customer?.status === 1 ? 'success' : 'danger'} badge-inline`}>
                            {customer?.status === 1 ? 'Active' : customer?.status === 0 ? 'Inactive' : 
                            customer?.status === 3 ? 'Account Blocked' : 'No Plan Selected'}
                        </span>
                    </td>
                    <td>
                        <span className={`badge badge-light-${customer?.is_verified === false ? 'danger' : 'success'} badge-inline`}>
                            {customer?.is_verified === false ? 'Not Verified' : 'Verified'}
                        </span>
                    </td>
                    <td>
                        {" "}
                        <button
                            className="btn btn-primary btn-sm ms-2 p-1 cursor-pointer " id={`send-email-${customer?._id}`}
                            onClick={() => {
                                sendResetPassword(customer.email, customer._id)
                            }}
                        >
                            Reset Password
                        </button>
                        </td>
                   
                    <td className=''>
                        <div className='d-flex flex-shrink-0'>
                            <button onClick={(e) => viewDetails(customer?._id)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' title='View Details'
                            >
                            <KTSVG
                                path='/media/icons/duotune/general/gen028.svg'
                                className='svg-icon-3 text-primary'
                            />
                            </button>     

                            {/* <button onClick={() => editCustomer(customer._id)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3 text-primary' />
                            </button>
                            <button onClick={() => deleteCustomer(customer._id)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm '
                            >
                            <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3 text-primary text-white'
                            />
                            </button>    */}
                            
                        </div>
                    </td>
                </tr>
                )
            })
        )
    }
    
    const getData = async () => {
        setContentLoading(true);
        
        // if (searchQuery === '' && fromDate === undefined && toDate === undefined && sortBy === -1) {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/admin-all?from_date=${fromDate}&to_date=${toDate}&sort=${sortBy}&keyword=${searchQuery}&offset=${offset}&limit=${dataPerPage}`, { headers: {"Authorization" : ` ${token}`} })
            
            const data = res.data;
            // console.log(data);
            const customer_data = data.customers;
            const customerData = getAllData(customer_data);
            // console.log(customerData);
            setAllData(customerData);
            setPageCount(Math.ceil(data.total / dataPerPage));
            setTotalItems(data.total);
        // }else{
        //     getDataBySearch();
           
        // }
        setContentLoading(false);
    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * dataPerPage);
        // console.log(newOffset);
        setOffset(newOffset);
        setDataPerPage(dataPerPage);
    };
    const viewDetails = (customer_id) => {
        try{
            axios.get(`${process.env.REACT_APP_API_URL}/user/profile-admin/${customer_id}`, { headers: {"Authorization" : ` ${token}`} })
            .then(res => {
                if(res.data.statusCode === 200){
                    const data = res.data.customer;
                    // (data._id);
                    setCustomerId(data._id);
                    setFullName(data.full_name);
                    setEmail(data.email);
                    setPhone(data.phone_number);
                    setPracticeAddress(data.practice_address);
                    setPracticeWebsite(data.practice_website);
                    setPracticeName(data.practice_name);
                    setLeadSource(data.lead_source);
                    setState(data.state);
                    setIsVerified(data.is_verified);
                    setCustomerStatus(data.status);
                    setSubscriptionData(data.subscriptions);
                    setViewMode(true);
                    handleShow("", 'View Details', 'View Details', true);
                }else{
                    toast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err);
                toast.error("Something went wrong");
            })

        }catch(err){
            console.log(err);
        }
    }
   

    const handleShow = (e, title="View Details", button="View Details", viewMode=false) => {
        if(viewMode){
            setViewMode(true);
        }else{
            setViewMode(false);
        }
    
    // console.log(title, button);
        setModalTitle(title);
        setModalTitle(button);
        setShow(true);
    }
    const handleClose = () => {
        setCustomerId('');
        setFullName('');
        setEmail('');
        setPhone('');
        setStatus(1);
        setSubscriptionData([]);
        setViewMode(false);

        setShow(false);

    }

    const handleCustomerForm = (e) => {
        e.preventDefault();
        if(customerId !== ''){
            const data = {
                status: customerStatus
            }

            try{
                axios.patch(`${process.env.REACT_APP_API_URL}/user/update-profile-admin/${customerId}`, data, { headers: {"Authorization" : ` ${token}`} })
                .then(res => {
                    if(res.data.statusCode === 200){
                        handleClose();
                        getData();
                        toast.success(res.data.message);
                    }else{
                        toast.error(res.data.message);
                    }
                })
                .catch(err => {
                    toast.error(err.response.data.message);
                }
                )
            }catch(err){
                console.log(err);
                toast.error("Something went wrong!")
            }
        }else{
            const data = {
            }

            try{
                axios.post(`${process.env.REACT_APP_API_URL}/account`, data, { headers: {"Authorization" : ` ${token}`} })
                .then(res => {
                    if(res.data.statusCode === 201){
                        handleClose();
                        getData();
                        toast.success(res.data.message);
                    }else{
                        toast.error(res.data.message);
                    }
                })
                .catch(err => {
                    toast.error(err.response.data.message);
                }
                )
            }catch(err){
                console.log(err);
                toast.error("Something went wrong!")
            }
        }
    }


    const changeDataRange = (e) => {
        setCalenderDates([e.selection]);
        // convert "Tue Mar 14 2023 00:00:00 GMT+0500 (Pakistan Standard Time)" to "2023-03-14"
        const startdate = new Date(e.selection.startDate);
        const enddate = new Date(e.selection.endDate);
        const startYear = startdate.getFullYear();
        const startMonth = startdate.getMonth()+1;
        const startDay = startdate.getDate();
        const endYear = enddate.getFullYear();
        const endMonth = enddate.getMonth()+1;
        const endDay = enddate.getDate();
        const startDate = `${startYear}-${startMonth}-${startDay}`;
        const endDate = `${endYear}-${endMonth}-${endDay}`;

        setFromDate(startDate);
        setToDate(endDate);
       
    }

    const sendResetPassword = (email, id) =>{
        
        if(!email && email==""){
            toast.error("Please enter a valid email address");
            return;
        }

        if(window.confirm("Are you sure you want to send this email?")){
            const btn = document.getElementById(`send-email-${id}`);
            
            btn.disabled = true;
            btn.innerHTML = "Sending..."
            try{
                axios.post(`${process.env.REACT_APP_API_URL}/user/reset-password-link`, {email}, { headers: {} })
               .then(res => {
                    if(res.data.status === "success"){
                        toast.success(res.data.message);
                    }else{
                        toast.error(res.data.message);
                    }

                    btn.disabled = false;
                    btn.innerHTML = "Reset Password"
                })
               .catch(err => {
                    toast.error(err.response.data.message);
                    btn.disabled = false;
                    btn.innerHTML = "Reset Password"}
                )
            }catch(err){
                console.log(err);
                btn.disabled = false;
                btn.innerHTML = "Reset Password"
                toast.error("Something went wrong!")
            }
        }

    }

    const resetCredit = (id) => {
        if(window.confirm("Are you sure you want to reset the credit?")){
            const btn = document.getElementById(`reset-credit-${id}`);
            btn.disabled = true;
            btn.innerHTML = "Resetting..."
            try{
                axios.post(`${process.env.REACT_APP_API_URL}/user/reset-credit`, {
                    user_id: id
                }, { headers: {
                    "Authorization" : ` ${token}`,
                    "Content-Type": "application/json"
                } })
               .then(res => {
                    if(res.data.status === "success"){
                        toast.success(res.data.message);
                    }else{
                        toast.error(res.data.message);
                    }

                    btn.disabled = false;
                    btn.innerHTML = "Reset Credit"
                }).finally(()=>{
                    // modal close
                    handleClose();
                })
               .catch(err => {
                    toast.error(err.response.data.message);
                    btn.disabled = false;
                    btn.innerHTML = "Reset Credit"}
                )
            }catch(err){
                console.log(err);
                btn.disabled = false;
                btn.innerHTML = "Reset Credit"
                toast.error("Something went wrong!")
            }
        }
    }

    const handleCustomerFunc = (e, action) => {
        e.preventDefault();
        var data = {};
        if(action === "verification"){
            setIsVerified(e.target.value);
            data = {
                is_verified: e.target.value
            }
        }
        if(action === "status"){   
            setCustomerStatus(e.target.value);
            data = {
                status: e.target.value
            }
        }
        try{
            axios.patch(`${process.env.REACT_APP_API_URL}/user/update-profile-admin/${customerId}`, data, { headers: {"Authorization" : ` ${token}`} })
            .then(res => {
                if(res.data.statusCode === 200){
                    getData();
                    toast.success(res.data.message);
                    handleClose();
                }else{
                    toast.error(res.data.message);
                }
            })
            .catch(err => {
                toast.error(err.response.data.message);
            }
            )
        }catch(err){
            console.log(err);
            toast.error("Something went wrong!")
        }

    }


   
    return (
        <>
            <PageTitle breadcrumbs={[]}>Manage Customers</PageTitle>
            <div className={`card card-light`}>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Total Customers <span className='p-1 mt-1 fw-semibold fs-7 bg-primary text-white rounded'>{totalItems<10?'0'+totalItems:totalItems}</span></span>
                    
                    </h3>
                    {/* <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                    >
                    <button
                        onClick={e=>handleShow(e, "Upload New Blog", "Upload New Blog")}
                        className='btn btn-sm btn-light-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#category_modal'
                    >
                        <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3 ' />
                        New Blog
                    </button>
                    </div> */}
                </div>
                <div className='d-flex px-5'>
                        {/* <div className='card-toolbar'> */}
                            <div className='min-w-200px pe-2'>
                                {/* <Select options={patientSelect} onChange={e=>setPatientSearch(e.value)} placeholder="Select Patient" /> */}
                                <input type="text" className="form-control form-control-sm" placeholder="Search" onChange={e=>setSearchQuery(e.target.value)} value={searchQuery} />
                            </div>
                            
                            {/* from date - to date calender */}
                            <div className='dropdown dropdown-inline'>
                                <button
                                type='button'
                                className='btn btn-sm btn-light-primary'
                                data-bs-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'
                                >
                                    <Calendar size={18}
                                    path='/media/icons/duotune/arrows/arr072.svg'
                                    className='svg-icon-2 svg-icon-gray-500'
                                    />
                                </button>
                                <div className='dropdown-menu dropdown-menu-sm dropdown-menu-end'>
                                    {/*begin::Navigation*/}

                                    <DateRange
                                        editableDateInputs={true}
                                        onChange={item => changeDataRange(item)}
                                        moveRangeOnFirstSelection={false}
                                        ranges={calenderDates}
                                    />
                                </div>
                            </div>
                            &nbsp;
                            {/* sorting dropdown */}
                            {/* <div className='dropdown dropdown-inline'>
                                <button
                                type='button'
                                className='btn btn-sm btn-light-primary'
                                data-bs-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'
                                >
                                    <Funnel size={18}
                                    path='/media/icons/duotune/arrows/arr072.svg'
                                    className='svg-icon-2 svg-icon-gray-500'
                                    />
                                </button>
                                
                                <div className='dropdown-menu dropdown-menu-sm dropdown-menu-end'>
                                    <ul className='navi navi-hover' style={{listStyle:"none"}}>
                                        <li className={`navi-item mb-2 pt-2 ${sortBy ===1? 'text-primary':''}` } onClick={e=>setSortBy(1)}>
                                            <span className='navi-text fw-bold' style={{cursor:"pointer"}}>Ascending</span>
                                        </li>
                                        <li className={`navi-item ${sortBy ===-1? 'text-primary':''}` } onClick={e=>setSortBy(-1)}>
                                            <span className='navi-text fw-bold' style={{cursor:"pointer"}}>Descending</span>
                                        </li>
                                    </ul>
                                </div>
                            
                        </div> */}
                    </div>

                {/* </div> */}
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold'>
                                    <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                            className='form-check-input'
                                            disabled
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-9-check'
                                            />
                                        </div>
                                    </th>
                                    <th className='min-w-150px'>Full Name</th>
                                    <th className='min-w-150px'>Email</th>
                                    <th className='min-w-150px'>Phone</th>
                                    <th className='min-w-150px'>Status</th>
                                    <th className='min-w-150px'>Verification</th>
                                    <th className="min-w-150px">Reset Password</th>
                                    <th className='min-w-50px'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allData}
                            </tbody>
                        </table>

                        
                        <div className='text-center'>
                            {/* <span>{totalItems}</span> */}
                            <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination mb-5"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} />
                        </div>
                    </div>
                </div>
            </div>

            {/* ====================================== */}
                            {/* Modal */}
            {/* ====================================== */}
            
            <Modal onHide={handleClose} className='modal fade' size="lg" show={lgshow}>
                {/* <Modal.Header closeButton >
                <Modal.Title className='h5'>{modalTitle}</Modal.Title>
                </Modal.Header> */}
                <div className='modal-header pb-0 border-0 justify-content-end'>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' onClick={handleClose}>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                    </div>
                </div>
                <Modal.Body className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                    <div className='text-center mb-13'>
                        <h1 className='mb-3'>{modalTitle}</h1>
                    </div>

                    <Form>
                        
                        <Form.Group className='mt-3' controlId="BlogTitle">
                            <Form.Label>Full Name <span className='text-danger'>*</span></Form.Label>
                            <Form.Control className='form-control-sm' type="text" placeholder="Full Name" value={full_name} onChange={(e)=> setFullName(e.target.value)} required disabled={viewMode}/>
                        </Form.Group>
                        <Form.Group className='mt-3' controlId="MetaTitle">
                            <Form.Label>Email <span className='text-danger'>*</span></Form.Label>
                            <Form.Control className='form-control-sm' type="text" placeholder="Email Address" value={email} onChange={(e)=> setEmail(e.target.value)} required disabled={viewMode}/>
                            
                        </Form.Group>
                        <Form.Group className='mt-3' controlId="MetaKeywords">
                            <Form.Label>Phone Number <span className='text-danger'>*</span></Form.Label>
                            <Form.Control className='form-control-sm' type="text" placeholder="Phone Number" value={phone} onChange={(e)=> setPhone(e.target.value)} required disabled={viewMode}/>
                        </Form.Group>
                        
                        {/* practive address */}
                        {/* practice website */}
                        {/* state */}
                        {/* is verified */}
                        <Form.Group className='mt-3' controlId="formBasicStatus">
                            <Form.Label>Practice Name</Form.Label>
                            <Form.Control className='form-control-sm' type="text" placeholder="Practice Name" value={practice_name} disabled={viewMode}/>
                        </Form.Group>
                        <Form.Group className='mt-3' controlId="formBasicStatus">
                            <Form.Label>Practice Address</Form.Label>
                            <Form.Control className='form-control-sm' type="text" placeholder="Practice Address" value={practice_address} disabled={viewMode}/>
                        </Form.Group>
                        
                        <Form.Group className='mt-3' controlId="formBasicStatus">
                            <Form.Label>Practice Website</Form.Label>
                            <Form.Control className='form-control-sm' type="text" placeholder="Practice Website" value={practice_website} disabled={viewMode}/>
                        </Form.Group>

                        <Form.Group className='mt-3' controlId="formBasicStatus">
                            <Form.Label>State</Form.Label>
                            <Form.Control className='form-control-sm' type="text" placeholder="State" value={state} disabled={viewMode}/>
                        </Form.Group>
                        <Form.Group className='mt-3' controlId="formBasicStatus">
                            <Form.Label>Lead Source</Form.Label>
                            <Form.Control className='form-control-sm' type="text" placeholder="Lead Source" value={lead_source} disabled={viewMode}/>
                        </Form.Group>

                        <Form.Group className='mt-3' controlId="formBasicStatus">
                            <Form.Label>Account Verified</Form.Label>
                            <Form.Select aria-label="Default select example" className='form-select-sm' value={is_verified} onChange={(e)=> {
                                handleCustomerFunc(e, "verification", customerId, e.target.value)
                            }
                            }>
                                <option value='true'>Verified</option>
                                <option value='false'>Not Verified</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='mt-3' controlId="formBasicStatus">
                            <Form.Label>Status</Form.Label>
                            <Form.Select aria-label="Default select example" className='form-select-sm' value={customerStatus} onChange={(e)=> {
                                handleCustomerFunc(e, "status", customerId, e.target.value)
                            }}>
                                <option value='1'>Active</option>
                                <option value='3'>Block</option>
                                <option value='0' disabled>Inactive</option>
                                <option value='2' disabled>No Plan Selected</option>
                            </Form.Select>
                        </Form.Group>
                        
                    </Form>
                    
                    {
                        subscriptionData?.length === 0 || customerStatus !== 1 ? '' : (
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 border mt-5'>
                                <thead>
                                    <tr className='fw-bolder text-muted bg-light'>
                                        <th></th>
                                        <th className='min-w-150px'>Plan</th>
                                        <th className='min-w-150px'>Start Date</th>
                                        <th className='min-w-150px'>End Date</th>
                                        <th className='min-w-100px'>Duration</th>
                                        <th className='min-w-100px'>Status</th>
                                        <th className='min-w-100px'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {subscriptionData?.map((item, index) => {
                                        // console.log(item);
                                        if(item?.subscription_plan_type){
                                            return (
                                                <tr key={index}>
                                                    <td></td>
                                                    <td>{item?.subscription_plan}
                                                        {item?.subscription_plan_type==="Free"&&item?.current_status==="active"? " (Remaining Credits: "+item?.remaining_credits+")":""}
                                                    </td>
                                                    <td>{moment(item?.subscription_start_date).format('DD MMMM, YYYY')}</td>
                                                    <td>{moment(item?.subscription_end_date).format('DD MMMM, YYYY')}</td>
                                                    <td>{item?.subscription_duration}</td>
                                                    <td>
                                                        <span className={`badge badge-light-${item?.current_status === "active" ? 'success' : 'danger'} badge-inline`}>
                                                            {item?.current_status}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {/*  if subscription is free then add reset credit */}
                                                        {item?.subscription_plan_type==="Free"&&item?.current_status==="active"?
                                                        <button className="btn btn-primary btn-sm ms-2 p-1 cursor-pointer " id={`reset-credit-${item?.user_id}`}
                                                            onClick={() => {
                                                                resetCredit(item.user_id)
                                                            }}
                                                        >
                                                            Reset Credits
                                                        </button>:""}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    }
                                    )}
                                </tbody>
                            </table>
                        )
                    }

                </Modal.Body>
                    {/* modal footer */}
                    {viewMode ? '': (
                        <div className='modal-footer d-flex justify-content-center pt-4'>
                            <button type='button' className='btn btn-sm btn-flex btn-light btn-active-light-primary me-2' onClick={handleClose}>
                                Close
                            </button>
                            <button type='button' className='btn btn-sm btn-flex btn-primary' id="btn-submit" onClick={handleCustomerForm}>
                                {modalTitle}
                            </button>
                        </div>
                    )}
            </Modal>
        </>
    )
}

export default Customers
