import {useEffect, useState, useRef} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import {KTSVG} from '../../_metronic/helpers'
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
import { Funnel,  Calendar, FileEarmarkBreak, FileEarmarkExcel, FileEarmarkX, ArrowClockwise, ExclamationCircle } from 'react-bootstrap-icons';
import moment from 'moment';
import axios from 'axios';

import {
    recordingGetApi,
    // regenerateResultApi,
    reAttemptResultApi
} from '../services/recording.service';
import Select from 'react-select'

import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css fil

// import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Spinner } from 'react-bootstrap';

const Recordings = () => {
    const [offset, setOffset] = useState(0);
    const [fromDate, setFromDate] = useState(''); 
    const [toDate, setToDate] = useState('');
    const [sortBy, setSortBy] = useState(-1);
    const [contentLoading, setContentLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [allData, setAllData] = useState([]);
    const [pageCount, setPageCount] = useState(0)
    const [totalItems, setTotalItems] = useState(0)
    const [dataPerPage, setDataPerPage] = useState(20)
    const [recordingResult, setRecordingResult] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [customerSelect, setCustomerSelect] = useState('all');

    const selectInputRef = useRef();

    const [calenderDates, setCalenderDates] = useState([
        {
            startDate: null,
            endDate: new Date(),
            key: 'selection',
            
        }
    ]);

    const customStyles = {
        control: provided => ({
            ...provided,           
            height: 10,
            // width: 100,
            // padding: 10,
            margin: 0,
            marginLeft: 0,
            border: "1px solid #ced4da",
            fontSize: 13,
            backgroundColor: 'white',
            outline: 'none',
            '&:hover': {
                border: '1px solid #ced4da',
                outline: 'none',
            },
            '&:focus': {
                border: '1px solid #ced4da',
                outline: 'none',

            },

        })
      }
    
    
    useEffect(() => {
        getData();
    }, [sortBy, offset, calenderDates, fromDate, toDate, searchQuery, customerSelect])

    useEffect(() => {
        fetchCustomerData();    
    }, [])

    const getAllData = (data, offset) => {
        return (
            
            data.map(recording =>{
            
                return (
                <tr key={recording._id}>
                    <td></td>
                    <td>
                        {recording?.patient_name}
                    </td>
                    <td>
                        {recording?.doctor_name}
                    </td>
                    <td>
                        {recording?.uploading_plateform}
                    </td>
                    <td>
                        {moment(recording?.created_at).format('YYYY-MM-DD, h:mm:ss a')}
                    </td>
                    <td>
                        {/* status */}
                        <span className={`badge badge-light-${recording?.result === "success" ? 'success' : 'danger'} badge-inline`}>
                            {
                                recording?.result === "success" ? "Success" :
                                recording?.result === "pending" ? "Pending" :
                                recording?.result === "fail" ? "Fail" :
                                recording?.result === "large_file" ? "Large File" :
                                recording?.result === "file_not_found" ? "File Not Found" :
                                recording?.result === "transcribe_fail" ? "Transcription Fail" : ""
                            }
                        </span>
                    </td>
                    
                   
                    <td className=''>
                        <small className=''>
                            {recording.result === "pending" ?
                            <>
                                <Spinner animation="border" size="sm" className='me-2' />
                                <button className='btn btn-default px-1 py-0 fs-zk-14' id={`re-attempt-${recording._id}`} onClick={e => {
                                    e.stopPropagation(); // Prevent triggering the parent <Link>
                                    e.preventDefault(); // Prevent page reload
                                    reAttemptResult(recording._id, recording.doctor_physical_exam_type); // Call your function to confirm the delete
                                }
                                }>
                                    <ArrowClockwise size={18} />
                                </button>
                            </>
                            : recording.result === "fail"  ? 
                            <>
                                <ExclamationCircle className='text-danger' size={20} title='Failed' />
                                {/* re attempt */}
                                <button 
                                    className='btn btn-default px-1 py-0 fs-zk-14 ms-1' 
                                    title='Re-attempt'
                                    id={`re-attempt-${recording._id}`}
                                    onClick={e => {
                                        e.stopPropagation(); // Prevent triggering the parent <Link>
                                        e.preventDefault(); // Prevent page reload
                                        reAttemptResult(recording._id, recording.doctor_physical_exam_type); // Call your function to confirm the delete
                                    }}
                                >
                                    <ArrowClockwise size={18} />
                                </button>

                            </>
                            : recording.result === "large_file" ? 
                            <>
                                <FileEarmarkX className='text-danger' size={20} title='Large File' />
                                {/* <span className='badge badge-light-danger badge-inline'>Large File</span> */}
                            </>
                            : recording.result === "file_not_found" ?
                                <>
                                    <FileEarmarkExcel className='text-danger' size={20} title='File Not Found' />
                                </>
                            :recording.result === "transcribe_fail" ?
                            <>
                                <FileEarmarkBreak className='text-danger' size={20} title='Transcription Fail' />
                            </>
                            :""
                            }
                            
                        </small>

                    </td>
                </tr>
                )
            })
        )
    }
    
    const getData = async () => {
        try {
            setContentLoading(true);
    
            const res = await recordingGetApi(
                dataPerPage, 
                offset, 
                fromDate, 
                toDate, 
                sortBy, 
                searchQuery,
                customerSelect
            );
    
            if (res && res.recordings) {
                const recordingData = getAllData(res.recordings);
                setRecordingResult(res.recordings);
                setAllData(recordingData);
                setPageCount(Math.ceil(res.total / dataPerPage));
                setTotalItems(res.total);
            } else {
                setAllData([]);
                setPageCount(0);
                setTotalItems(0);
            }
        } catch (error) {
            console.error("Error fetching data:", error.message || error);
            // Optionally show an error message to the user
        } finally {
            setContentLoading(false);
        }
    };
    

    const handlePageClick = (event) => {
        const newOffset = (event.selected * dataPerPage);
        // console.log(newOffset);
        setOffset(newOffset);
        setDataPerPage(dataPerPage);
    };


    const changeDataRange = (e) => {
        setCalenderDates([e.selection]);
        // convert "Tue Mar 14 2023 00:00:00 GMT+0500 (Pakistan Standard Time)" to "2023-03-14"
        const startdate = new Date(e.selection.startDate);
        const enddate = new Date(e.selection.endDate);
        const startYear = startdate.getFullYear();
        const startMonth = startdate.getMonth()+1;
        const startDay = startdate.getDate();
        const endYear = enddate.getFullYear();
        const endMonth = enddate.getMonth()+1;
        const endDay = enddate.getDate();
        const startDate = `${startYear}-${startMonth}-${startDay}`;
        const endDate = `${endYear}-${endMonth}-${endDay}`;

        setFromDate(startDate);
        setToDate(endDate);
       
    }

    const reAttemptResult = async (id, physical_exam_type) => {
        const btn = document.getElementById(`re-attempt-${id}`);
        btn.disabled = true;
        try{
            // re-attempt the result generation
            const data = {
                patient_id: id,
                // user_id: userId,
                regenerate: true,
                physical_exam_type: physical_exam_type
            };

            // setIndividualLoading(true);
            // setProcessingPatientIds(prev => [...prev, id]);
            await reAttemptResultApi(data)
                .then((res) => {
                    if (res.statusCode === 200) {
                        // setRecordSelectedItem(res.updatedRecord);
                        toast.success("Result re-attempted successfully!");
                        getData();
                        // setRecordingsAll(res.patientRecord)
                    } else {
                        toast.error(res.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
        catch(err){
            toast.error("Internal service error");
        } finally {
            // setIndividualLoading(false);
            // setProcessingPatientIds(prev => prev.filter((id) => id !== id));
            btn.disabled = false;
        }
    }

    const fetchCustomerData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/customers?is_not_developer=true`);
            // add all patients and select top 10 patients ON TOP OF THE LIST
            // res.data.unshift({ _id: 'top-10', full_name: 'Top 10 Customers' });
            res.data.unshift({ _id: 'all', full_name: 'All Customers' });
            
            const options = res.data.map(customer => {
                return {
                    value: customer._id,
                    label: customer.full_name
                }
            })
            setCustomerData(options);
            

        } catch (error) {
            console.error('Error fetching customer data:', error);
        }
    }

   
    return (
        <>
            <PageTitle breadcrumbs={[]}>Recordings</PageTitle>
            <div className={`card card-light`}>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Total Results <span className='p-1 mt-1 fw-semibold fs-7 bg-primary text-white rounded'>{totalItems<10?'0'+totalItems:totalItems}</span></span>
                    </h3>
                </div>
                <div className='d-flex px-5'>
                        {/* <div className='card-toolbar'> */}
                            <div className='min-w-300px pe-2 ms-5'>
                                {/* <Select options={patientSelect} onChange={e=>setPatientSearch(e.value)} placeholder="Select Patient" /> */}
                                <input type="text" className="form-control form-control-sm" placeholder="Search" onChange={e=>setSearchQuery(e.target.value)} value={searchQuery} />
                            </div>
                            <div className='min-w-300px pe-5 ms-5'>
                            <Select
                                id="customerData" 
                                styles={customStyles} 
                                options={customerData} 
                                menuPortalTarget={document.querySelector('body')} 
                                value={
                                    customerData.find(obj => obj.value === selectInputRef.current?.value)
                                }
                                onChange={
                                    e => setCustomerSelect(e.value)
                                }
                                placeholder="Select Customer" 
                                ref={selectInputRef}/>
                            </div>
                            
                            {/* from date - to date calender */}
                            <div className='dropdown dropdown-inline'>
                                <button
                                type='button'
                                className='btn btn-sm btn-light-primary'
                                data-bs-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'
                                >
                                    <Calendar size={18}
                                    path='/media/icons/duotune/arrows/arr072.svg'
                                    className='svg-icon-2 svg-icon-gray-500'
                                    />
                                </button>
                                <div className='dropdown-menu dropdown-menu-sm dropdown-menu-end'>
                                    {/*begin::Navigation*/}
                                    <DateRange
                                        editableDateInputs={true}
                                        onChange={item => changeDataRange(item)}
                                        moveRangeOnFirstSelection={false}
                                        ranges={calenderDates}
                                    />
                                </div>
                            </div>
                            &nbsp;
                    </div>

                {/* </div> */}
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold'>
                                    <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                            className='form-check-input'
                                            disabled
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-9-check'
                                            />
                                        </div>
                                    </th>
                                    <th className='min-w-150px'>Patient Name</th>
                                    <th className='min-w-150px'>Doctor</th>
                                    <th className='min-w-150px'>Platform</th>
                                    <th className='min-w-150px'>Date</th>
                                    <th className='min-w-150px'>Status</th>
                                    <th className='min-w-50px'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allData}
                            </tbody>
                        </table>

                        
                        <div className='text-center'>
                            {/* <span>{totalItems}</span> */}
                            <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination mb-5"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Recordings
