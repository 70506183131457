import { useEffect, useRef, useState } from 'react';
import EChartComponent from '../../_metronic/layout/components/chart/EChartComponent';
import axios from 'axios';  // Ensure axios is imported
import Select from 'react-select'

const SystemUsage = () => {
    const [anylyticsData, setAnalyticsData] = useState([]);
    const [selectDay, setSelectDay] = useState('today');
    const [customerSelect, setCustomerSelect] = useState('top-10');
    const [customerData, setCustomerData] = useState([]);

    const selectInputRef = useRef();

    // Fetch Daily Analytics
    const fetchAnalytics = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/graph/analytics`, {
                params: {
                    day: selectDay,
                    customer: customerSelect
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${localStorage.getItem('token')}`
                }
            }); // Adjust API route
            const data = response.data;
            setAnalyticsData(data);
        } catch (error) {
            console.error('Error fetching daily analytics:', error);
        }
    };

    const fetchCustomerData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/customers?is_not_developer=true`);
            // add all patients and select top 10 patients ON TOP OF THE LIST
            res.data.unshift({ _id: 'top-10', full_name: 'Top 10 Customers' });
            res.data.unshift({ _id: 'all', full_name: 'All Customers' });
            
            const options = res.data.map(customer => {
                return {
                    value: customer._id,
                    label: customer.full_name
                }
            })
            setCustomerData(options);
            

        } catch (error) {
            console.error('Error fetching customer data:', error);
        }
    }


    // UseEffect to fetch analytics data when component mounts
    useEffect(() => {
        fetchAnalytics()
        fetchCustomerData();
    }, []);

    useEffect(() => {
        fetchAnalytics();
    }, [selectDay, customerSelect]);


    const customStyles = {
        control: provided => ({
            ...provided,           
            height: 10,
            // width: 100,
            // padding: 10,
            margin: 0,
            marginLeft: 0,
            border: "1px solid #ced4da",
            fontSize: 13,
            backgroundColor: 'white',
            outline: 'none',
            '&:hover': {
                border: '1px solid #ced4da',
                outline: 'none',
            },
            '&:focus': {
                border: '1px solid #ced4da',
                outline: 'none',

            },

        })
      }

    return (
        <div className='container'>
            <h1>System Usage</h1>
            <div className='row mt-5'>
                <div className='col-md-6 mb-2'>
                    <select className='form-select form-select-sm' value={selectDay} onChange={e=>setSelectDay(e.target.value)} >
                        <option value='today'>Today</option>
                        <option value='yesterday'>Yesterday</option>
                        <option value='this-week'>This Week</option>
                        <option value='this-month'>This Month</option>
                        <option value='this-year'>This Year</option>
                    </select>
                </div>
                <div className='col-md-6 mb-2'>
                    {/*  customers */}
                    {/* <select className='form-select form-select-sm' value={customerSelect} onChange={e=>setCustomerSelect(e.target.value)} >
                        <option value='today'>Today</option>
                        {
                            customerData.map((customer, index) => {
                                return <option key={index} value={customer._id}>{customer.full_name}</option>
                            })
                        }
                    </select> */}
                    <Select
                        id="customerData" 
                        styles={customStyles} 
                        options={customerData} 
                        menuPortalTarget={document.querySelector('body')} 
                        onChange={e=>setCustomerSelect(e.value)} 
                        value={
                            customerData.find(option => option.value === customerSelect)
                        }
                        placeholder="Select Customer" 
                        ref={selectInputRef}/>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <EChartComponent colorCode={"#2F5597"} data={anylyticsData} chartName= {
                        selectDay === 'today' ? 'Today Usage' :
                        selectDay === 'yesterday' ? 'Yesterday Usage' :
                        selectDay === 'this-week' ? 'This Week Usage' :
                        selectDay === 'this-month' ? 'This Month Usage' :
                        selectDay === 'this-year' ? 'This Year Usage' : ''
                    }/> 
                </div>
            </div>
            
        </div>
    );
};

export default SystemUsage;